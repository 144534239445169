<template>
  <div class="gf-ys">
    {{ismarket ? name :''}}{{ hours | filterTimeData }}:{{ min | filterTimeData }}:{{
      sec | filterTimeData
    }}{{!ismarket ? name :''}}
  </div>
</template>

<script>
export default {
  name: "backDate",
  props: {
    start: String,
    end: String,
    text: {
      type: String,
      default: '后结束'
    },
    ismarket:Boolean
  },
  mounted() {
    this.BackDate();
  },
  data() {
    return {
      hours: "",
      min: "",
      sec: "",
      timer: "",
      name:"",
    };
  },
  beforeDestroy() {
    clearTimeout(this.timer);
  },
  methods: {
    BackDate() {
      // 获取当前时间
      let now = new Date().getTime();
      let end = new Date(`${this.end}`.replace(/-/g, "/")).getTime();// 活动结束时间
      let start = new Date(`${this.start}`.replace(/-/g, "/")).getTime();// 活动开始时间

      if(this.ismarket){
        this.name = now < start ? '距开始' : now < end ? '距结束' : '已结束';
        end = now < start ? start : end;
      }else{
        this.name = this.text;
      } 
      

      if (now >= end) {
        this.hours = 0;
        this.min = 0;
        this.sec = 0;
        return;
      }
      // 用结束时间戳减去当前时间
      let msec = end - now;
      this.hours = Math.floor((msec / (1000 * 60 * 60))); //算出小时数
      this.min = Math.floor((msec / (1000 * 60)) % 60); //算出分钟数
      this.sec = Math.floor((msec / 1000) % 60); //算出秒数

      //定义this指向
      const that = this;
      // 使用定时器 然后使用递归 让每一次函数能调用自己达到倒计时效果
      this.timer = setTimeout(function () {
        that.BackDate();
      }, 1000);
    },
  },
};
</script>

<style>
</style>