var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c(
        "div",
        { staticClass: "container", attrs: { "bi-route": "wdetail" } },
        [
          _c("div", { staticClass: "mask" }),
          _vm.slideData && _vm.slideData.length
            ? _c(
                "div",
                {
                  ref: "content_wrap",
                  staticClass: "content_wrap",
                  on: {
                    click: function($event) {
                      if ($event.target !== $event.currentTarget) {
                        return null
                      }
                      return _vm.handleClose.apply(null, arguments)
                    }
                  }
                },
                [
                  _vm.showTip
                    ? _c("div", { staticClass: "tip" }, [
                        _c("i"),
                        _c("p", [
                          _vm._v(
                            " 欢迎来到商品微详情，滑动鼠标滚轮或点击右侧小图片可快速查看更多相似推荐商品，点击领券购买或使用手机淘宝扫码可快速领券购买。 "
                          )
                        ]),
                        _c(
                          "span",
                          {
                            staticClass: "close_know",
                            on: { click: _vm.handleSetCookie }
                          },
                          [_vm._v("我知道了")]
                        )
                      ])
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "content" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "close",
                          style: {
                            left: _vm.left,
                            top: _vm.showTip ? "95px" : null
                          },
                          on: { click: _vm.handleClose }
                        },
                        [_c("i"), _c("span", [_vm._v("返回")])]
                      ),
                      _vm._l(_vm.slideData, function(item, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            ref: "card_item",
                            refInFor: true,
                            staticClass: "card_item_wrap",
                            attrs: {
                              "data-index": index,
                              "bi-attr": "{index: " + index + "}"
                            }
                          },
                          [
                            _c("div", { staticClass: "card_item" }, [
                              _c("span", {
                                staticClass: "prev",
                                on: {
                                  click: function() {
                                    return _vm.handleDotClick(
                                      index,
                                      item.active - 1
                                    )
                                  }
                                }
                              }),
                              _c("span", {
                                staticClass: "next",
                                on: {
                                  click: function() {
                                    return _vm.handleDotClick(
                                      index,
                                      item.active + 1
                                    )
                                  }
                                }
                              }),
                              _c(
                                "div",
                                { staticClass: "dot_nav" },
                                _vm._l(item.smallImages.length, function(i) {
                                  return _c("i", {
                                    key: i,
                                    class: {
                                      active: item.active == i
                                    },
                                    on: {
                                      click: function() {
                                        return _vm.handleDotClick(index, i)
                                      }
                                    }
                                  })
                                }),
                                0
                              ),
                              _c("div", { staticClass: "page_num" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(item.active) +
                                    " / " +
                                    _vm._s(item.smallImages.length) +
                                    " "
                                )
                              ]),
                              _vm.getMarketConfig(item)
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "active_mark",
                                      style: _vm.getMarketConfig(item, "style")
                                    },
                                    [
                                      _vm.getMarketConfig(item).goods_type ==
                                        2 &&
                                      item.quanMLink &&
                                      item.quanMLink != "0"
                                        ? _c("span", [
                                            _vm._v(
                                              "定金" +
                                                _vm._s(item.quanMLink) +
                                                "元抵" +
                                                _vm._s(
                                                  _vm.AccAdd({
                                                    num: [
                                                      item.quanMLink,
                                                      item.hzQuanOver || 0
                                                    ]
                                                  })
                                                ) +
                                                "元"
                                            )
                                          ])
                                        : _vm._e(),
                                      _vm.getMarketConfig(item).goods_type == 1
                                        ? _c("back-date", {
                                            attrs: {
                                              ismarket: true,
                                              start: _vm.getMarketConfig(item)
                                                .ac_start_time,
                                              end: _vm.getMarketConfig(item)
                                                .ac_end_time,
                                              text: " "
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                : item.activityType == 3 &&
                                  +new Date(item.activityEndTime) > +new Date()
                                ? _c(
                                    "div",
                                    { staticClass: "active_tag" },
                                    [
                                      _c("back-date", {
                                        attrs: {
                                          start: item.activityStartTime,
                                          end: item.activityEndTime,
                                          text: " "
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              item.discountFull
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "active_mark2",
                                      style:
                                        _vm.getMarketConfig(item) ||
                                        (item.activityType == 3 &&
                                          +new Date(item.activityEndTime) >
                                            +new Date())
                                          ? "left:240px;"
                                          : ""
                                    },
                                    [
                                      _vm._v(
                                        " 每满" +
                                          _vm._s(item.discountFull) +
                                          "减" +
                                          _vm._s(item.discountCut) +
                                          " "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                {
                                  staticClass: "img_wrap",
                                  style: {
                                    transform:
                                      "translateX(" + item.translateX + "px)"
                                  }
                                },
                                _vm._l(item.smallImages, function(item, index) {
                                  return _c("img", {
                                    key: index,
                                    attrs: {
                                      src: item + "_640x640.jpg",
                                      alt: ""
                                    }
                                  })
                                }),
                                0
                              )
                            ]),
                            _c("div", { staticClass: "card_info" }, [
                              _c("div", { staticClass: "row1" }, [
                                _c("span", { staticClass: "left" }, [
                                  _c("span", { staticClass: "item1" }, [
                                    _vm._v(" ￥")
                                  ]),
                                  _c("span", { staticClass: "item2" }, [
                                    _vm._v(_vm._s(item.actualPrice))
                                  ]),
                                  _c("span", { staticClass: "item3" }, [
                                    _vm._v("￥" + _vm._s(item.originalPrice))
                                  ]),
                                  item.specialText && item.specialText[0]
                                    ? _c("span", { staticClass: "tag" }, [
                                        _vm._v(_vm._s(item.specialText[0]))
                                      ])
                                    : _vm._e(),
                                  item.couponPrice
                                    ? _c("span", { staticClass: "tag" }, [
                                        _vm._v(
                                          _vm._s(item.couponPrice) + "元券 "
                                        )
                                      ])
                                    : _vm._e()
                                ]),
                                _c("span", { staticClass: "right" }, [
                                  _vm.getMarketConfig(item) &&
                                  _vm.getMarketConfig(item).goods_type == 1
                                    ? _c(
                                        "span",
                                        {
                                          attrs: { "card-key": "sales_label" }
                                        },
                                        [_vm._v("已售")]
                                      )
                                    : _vm.getMarketConfig(item) &&
                                      _vm.getMarketConfig(item).goods_type == 2
                                    ? _c(
                                        "span",
                                        {
                                          attrs: { "card-key": "sales_label" }
                                        },
                                        [_vm._v("已定")]
                                      )
                                    : _c(
                                        "span",
                                        {
                                          attrs: { "card-key": "sales_label" }
                                        },
                                        [_vm._v("已售")]
                                      ),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "num",
                                      attrs: { "card-key": "sales" }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("salesNum")(
                                            item.monthSales,
                                            _vm._,
                                            1
                                          )
                                        )
                                      )
                                    ]
                                  ),
                                  _c("span", [_vm._v("件")])
                                ])
                              ]),
                              _c("div", { staticClass: "row2" }, [
                                _c("span", { staticClass: "left" }, [
                                  _c("img", {
                                    attrs: {
                                      src: _vm.getShopImg(
                                        item.haitao,
                                        item.tchaoshi,
                                        item.shopType
                                      ),
                                      alt: ""
                                    }
                                  }),
                                  _c("span", { staticClass: "text" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(item.dtitle || item.title) +
                                        " "
                                    )
                                  ])
                                ]),
                                _c(
                                  "span",
                                  {
                                    staticClass: "right",
                                    attrs: { "bi-route": "goumai" }
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "code",
                                        attrs: { "bi-route": "saoma" },
                                        on: {
                                          mouseenter: function(e) {
                                            return _vm.handleCode(
                                              item,
                                              index,
                                              e
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("div", { staticClass: "scan_qr" }, [
                                          _c(
                                            "div",
                                            { staticClass: "qr_wrap" },
                                            [
                                              _vm.isShow
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass: "loadingImg"
                                                    },
                                                    [
                                                      _c("img", {
                                                        staticClass: "loading",
                                                        attrs: {
                                                          src: require("../../assets/detail/loading.png"),
                                                          alt: ""
                                                        }
                                                      })
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c("span", {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: !_vm.isShow,
                                                    expression: "!isShow"
                                                  }
                                                ],
                                                attrs: { id: "qrcode" + index }
                                              }),
                                              _c("p", [_vm._v("手淘扫码领券")]),
                                              _c("i", {
                                                staticClass: "sanjiao"
                                              })
                                            ]
                                          )
                                        ]),
                                        _c("img", {
                                          attrs: {
                                            src: require("../../assets/search/qr.png"),
                                            alt: ""
                                          }
                                        }),
                                        _c(
                                          "span",
                                          { staticClass: "code_text" },
                                          [_vm._v("扫码购买")]
                                        )
                                      ]
                                    ),
                                    _vm.getMarketConfig(item) &&
                                    _vm.getMarketConfig(item).goods_type == 2
                                      ? _c(
                                          "span",
                                          {
                                            staticClass: "buy",
                                            attrs: { "bi-route": "lingquan" },
                                            on: {
                                              click: function(e) {
                                                return _vm.handleBuy(
                                                  item,
                                                  index,
                                                  e
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v(" 领券预定 ")]
                                        )
                                      : _c(
                                          "span",
                                          {
                                            staticClass: "buy",
                                            attrs: { "bi-route": "lingquan" },
                                            on: {
                                              click: function(e) {
                                                return _vm.handleBuy(
                                                  item,
                                                  index,
                                                  e
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v(" 领券购买 ")]
                                        )
                                  ]
                                )
                              ]),
                              _c("div", { staticClass: "row3" }, [
                                _vm._m(0, true),
                                _c(
                                  "a",
                                  {
                                    staticClass: "right",
                                    on: {
                                      click: function() {
                                        return _vm.handleToDetail(item)
                                      }
                                    }
                                  },
                                  [
                                    _c("span", [_vm._v("去详情")]),
                                    _c("img", {
                                      attrs: {
                                        src: require("../../assets/search/right2.png"),
                                        alt: ""
                                      }
                                    })
                                  ]
                                )
                              ]),
                              _c("div", { staticClass: "row4" }, [
                                _c("span", { staticClass: "sanjiao" }),
                                _c("p", [_vm._v(" " + _vm._s(item.desc) + " ")])
                              ])
                            ])
                          ]
                        )
                      })
                    ],
                    2
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "slide_prod",
                      style: {
                        left: _vm.slide_prod_left,
                        top: _vm.showTip ? "95px" : null
                      }
                    },
                    [
                      _c("span", {
                        staticClass: "prev",
                        on: {
                          click: function() {
                            return _vm.handleSlideClick(Number(_vm.active) - 1)
                          }
                        }
                      }),
                      _c("span", {
                        staticClass: "next",
                        on: {
                          click: function() {
                            return _vm.handleSlideClick(Number(_vm.active) + 1)
                          }
                        }
                      }),
                      _c(
                        "div",
                        { ref: "slide_wrap", staticClass: "slide_wrap" },
                        [
                          _c(
                            "div",
                            { staticClass: "slide_wrap_move" },
                            _vm._l(_vm.slideData, function(item, index) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticClass: "slide_item",
                                  class: {
                                    active: _vm.active == index
                                  },
                                  on: {
                                    click: function() {
                                      return _vm.handleSlideClick(index)
                                    }
                                  }
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: item.mainPic + "_200x200.jpg",
                                      alt: ""
                                    }
                                  })
                                ]
                              )
                            }),
                            0
                          )
                        ]
                      )
                    ]
                  )
                ]
              )
            : _c("div", { staticClass: "loading" }, [_vm._v("...")])
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "left" }, [
      _c("img", {
        attrs: { src: require("../../assets/search/daren.png"), alt: "" }
      }),
      _vm._v("达人推荐 ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }