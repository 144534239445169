<template>
  <div class="container" v-if="visible" bi-route="wdetail">
    <div class="mask"></div>

    <div
      class="content_wrap"
      v-if="slideData && slideData.length"
      @click.self="handleClose"
      ref="content_wrap"
    >
      <div class="tip" v-if="showTip">
        <i></i>
        <p>
          欢迎来到商品微详情，滑动鼠标滚轮或点击右侧小图片可快速查看更多相似推荐商品，点击领券购买或使用手机淘宝扫码可快速领券购买。
        </p>
        <span class="close_know" @click="handleSetCookie">我知道了</span>
      </div>
      <div class="content">
        <div
          class="close"
          @click="handleClose"
          :style="{
            left: left,
            top: showTip ? '95px' : null,
          }"
        >
          <i></i>
          <span>返回</span>
        </div>
        <div
          class="card_item_wrap"
          v-for="(item, index) in slideData"
          :key="index"
          ref="card_item"
          :data-index="index"
          :bi-attr="`{index: ${index}}`"
        >
          <div class="card_item">
            <span
              class="prev"
              @click="() => handleDotClick(index, item.active - 1)"
            >
            </span>
            <span
              class="next"
              @click="() => handleDotClick(index, item.active + 1)"
            >
            </span>
            <div class="dot_nav">
              <i
                v-for="i in item.smallImages.length"
                :class="{
                  active: item.active == i,
                }"
                :key="i"
                @click="() => handleDotClick(index, i)"
              ></i>
            </div>
            <div class="page_num">
              {{ item.active }} / {{ item.smallImages.length }}
            </div>
            <!-- 非定金类型商品出现倒计时 -->
            <div
              class="active_mark"
              v-if="getMarketConfig(item)"
              :style="getMarketConfig(item, 'style')"
            >
              <span
                v-if="
                  getMarketConfig(item).goods_type == 2 &&
                    item.quanMLink &&
                    item.quanMLink != '0'
                "
                >定金{{ item.quanMLink }}元抵{{
                  AccAdd({
                    num: [item.quanMLink, item.hzQuanOver || 0],
                  })
                }}元</span
              >
              <back-date
                :ismarket="true"
                v-if="getMarketConfig(item).goods_type == 1"
                :start="getMarketConfig(item).ac_start_time"
                :end="getMarketConfig(item).ac_end_time"
                text=" "
              />
            </div>
            <!-- 聚划算 -->
            <div
              v-else-if="
                item.activityType == 3 &&
                  +new Date(item.activityEndTime) > +new Date()
              "
              class="active_tag"
            >
              <back-date
                :start="item.activityStartTime"
                :end="item.activityEndTime"
                text=" "
              />
            </div>
            <!-- 满减 -->
            <div
              class="active_mark2"
              v-if="item.discountFull"
              :style="
                getMarketConfig(item) ||
                (item.activityType == 3 &&
                  +new Date(item.activityEndTime) > +new Date())
                  ? 'left:240px;'
                  : ''
              "
            >
              每满{{ item.discountFull }}减{{ item.discountCut }}
            </div>

            <div
              class="img_wrap"
              :style="{ transform: `translateX(${item.translateX}px)` }"
            >
              <img
                :src="`${item}_640x640.jpg`"
                alt=""
                v-for="(item, index) in item.smallImages"
                :key="index"
              />
            </div>
          </div>
          <div class="card_info">
            <div class="row1">
              <span class="left">
                <span class="item1"> ￥</span>
                <span class="item2">{{ item.actualPrice }}</span>
                <span class="item3">￥{{ item.originalPrice }}</span>
                <span
                  class="tag"
                  v-if="item.specialText && item.specialText[0]"
                  >{{ item.specialText[0] }}</span
                >
                <span class="tag" v-if="item.couponPrice"
                  >{{ item.couponPrice }}元券
                </span>
              </span>
              <span class="right">
                <span
                  card-key="sales_label"
                  v-if="
                    getMarketConfig(item) &&
                      getMarketConfig(item).goods_type == 1
                  "
                  >已售</span
                >
                <span
                  card-key="sales_label"
                  v-else-if="
                    getMarketConfig(item) &&
                      getMarketConfig(item).goods_type == 2
                  "
                  >已定</span
                >
                <span card-key="sales_label" v-else>已售</span>
                <span class="num" card-key="sales">{{
                  item.monthSales | salesNum(_,1)
                }}</span>
                <span>件</span>
              </span>
            </div>
            <div class="row2">
              <span class="left">
                <img
                  :src="getShopImg(item.haitao, item.tchaoshi, item.shopType)"
                  alt=""
                />
                <span class="text">
                  {{ item.dtitle || item.title }}
                </span>
              </span>
              <span class="right" bi-route="goumai">
                <span class="code" bi-route="saoma" @mouseenter="(e) => handleCode(item, index, e)">
                  <div class="scan_qr">
                    <div class="qr_wrap">
                      <div class="loadingImg" v-if="isShow">
                        <img
                          src="../../assets/detail/loading.png"
                          class="loading"
                          alt=""
                        />
                      </div>
                      <span :id="`qrcode${index}`" v-show="!isShow"> </span>
                      <!-- <img alt="" card-key="qr_code" ref="qr_code" /> -->
                      <p>手淘扫码领券</p>
                      <i class="sanjiao"></i>
                    </div>
                  </div>
                  <img src="../../assets/search/qr.png" alt="" />
                  <span class="code_text">扫码购买</span>
                </span>
                  <span
                  class="buy"
                  v-if="
                    getMarketConfig(item) &&
                      getMarketConfig(item).goods_type == 2
                  "
                  @click="(e) => handleBuy(item, index, e)"
                  bi-route='lingquan'
                  >
                  领券预定
                  </span>
                <span bi-route='lingquan' class="buy" v-else @click="(e) => handleBuy(item, index, e)">
                  领券购买
                  </span>
              </span>
            </div>
            <div class="row3">
              <span class="left">
                <img src="../../assets/search/daren.png" alt="" />达人推荐
              </span>
              <a class="right" @click="() => handleToDetail(item)">
                <span>去详情</span>
                <img src="../../assets/search/right2.png" alt="" />
              </a>
            </div>
            <div class="row4">
              <span class="sanjiao"></span>
              <p>
                {{ item.desc }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        class="slide_prod"
        :style="{
          left: slide_prod_left,
          top: showTip ? '95px' : null,
        }"
      >
        <span
          class="prev"
          @click="() => handleSlideClick(Number(active) - 1)"
        ></span>
        <span
          class="next"
          @click="() => handleSlideClick(Number(active) + 1)"
        ></span>
        <div class="slide_wrap" ref="slide_wrap">
          <div class="slide_wrap_move">
            <div
              class="slide_item"
              v-for="(item, index) in slideData"
              :key="index"
              :class="{
                active: active == index,
              }"
              @click="() => handleSlideClick(index)"
            >
              <img :src="`${item.mainPic}_200x200.jpg`" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="loading" v-else>...</div>
  </div>
</template>

<script>
import { getGoodsByOpen, getGoodsDetail } from "@/services";
import "intersection-observer";
import { importCDN, SetCookie, GetCookie, AccAdd } from "@/utils/utils";
import { mapGetters } from "vuex";
import BackDate from "@/components/BackDate";
export default {
  components: {
    BackDate,
  },
  name: "microdetail",
  props: {
    visible: {
      //是否展示
      type: Boolean,
      default: true,
    },
    id: {
      type: "string",
      default: "",
    },
  },
  computed: {
    ...mapGetters(["marketGroup", "config", "marketGroupConfig"]),
  },
  beforeDestroy() {
    document.body.style.overflow = "visible";
  },
  data() {
    return {
      bizSceneId:1,
      marketConfig: {},
      slideData: [],
      active: 0,
      isObserve: true,
      left: "30%",
      slide_prod_left: "67%",
      ShortLink: "",
      Link: "",
      isTransformLink: true, //是否可转链
      showTip: false,
      isShow: false,
    };
  },
  mounted() {
    if (GetCookie("micro-detail-tip") != 1) {
      this.showTip = true;
    }
    importCDN("https://public.ffquan.cn/lib/base64.min.js");
    importCDN("https://public.ffquan.cn/lib/qrcode.js");
    getGoodsByOpen({ id: this.id, supportImages: 1, size: 20 }).then((res) => {
      if (res && res.code === 0 && res.data) {
        let newData = res.data.map((item) => {
          item.active = 1;
          item.translateX = 0;
          item.smallImages = item.smallImages
            ? [item.mainPic, ...item.smallImages]
            : [item.mainPic];
          return item;
        });
        this.slideData = [...this.slideData, ...newData];
      }
    });
    let obj = {};
    if (this.id.length > 8) {
      obj.goodsId = this.id;
    } else {
      obj.id = this.id;
    }
    getGoodsDetail(obj).then((res) => {
      let obj = res.data;
      obj.smallImages = obj.imgs ? obj.imgs.split(",") : [];
      obj.active = 1;
      obj.translateX = 0;
      this.slideData = [{ ...obj }, ...this.slideData];
      this.bizSceneId = obj.bizSceneId;
    });
    document.body.style.overflow = "hidden";
  },
  methods: {
    AccAdd: AccAdd,
    // 读取当前商品活动配置
    getMarketConfig(item, isStyle) {
      const market = (item.marketGroup || [])
        .map((res) => this.marketGroupConfig[res])
        .filter((res) => res)[0];
      return market
        ? isStyle
          ? `background-image:url(${market.pc_mirc_banner}); text-align: right;`
          : market
        : "";
    },
    async handleBuy(item, index, e) {
      if (item.ShortLink || item.Link) {
        if (e) {
          this.handleBiEvent(item, e)
        }
        window.open(item.ShortLink || item.Link);
        return;
      }
      const res = await this.transformLink(item, index, undefined, e);
      if (res && res.code && res.code === 1) {
        window.open(res.data.ShortLink || res.data.Link);
      }else{
         window.open(res);
      }
    },
    async handleCode(item, index, e) {
      if(this.isShow) return;
      let str = `qrcode${index}`;
      if (item.ShortLink || item.Link) {
        if (e) {
          if(!document.getElementById(str).title){
            new QRCode(document.getElementById(str), {
              text: item.ShortLink,
              height: 100,
              width: 100,
            });
          }
          this.handleBiEvent(item, e)
        }
        return;
      }
      this.isShow = true;
      const res = await this.transformLink(item, index, undefined, e);
      let url = '';
      if (res && res.code && res.code === 1) {
        url = res.data.ShortLink;
      }else{
        url = res;
      }
      new QRCode(document.getElementById(str), {
        text: url,
        height: 100,
        width: 100,
      });
      this.isShow = false;
    },
    handleDotClick(index, i) {
      let length = this.slideData[index].smallImages.length;
      if (i > length) {
        return;
      }
      if (i < 1) {
        return;
      }
      this.slideData[index].active = Number(i);
      this.slideData[index].translateX = -(600 * (Number(i) - 1));
    },
    getShopImg(haitao, tchaoshi, shopType) {
      if (haitao == 1) return require("../../assets/search/prod_icon2.png");
      if (tchaoshi == 1) return require("../../assets/search/prod_icon3.png");
      if (shopType == 1) return require("../../assets/search/prod_icon1.png");
      return require("../../assets/search/prod_icon1.png");
    },
    handleSlideClick(index) {
      if (index < 0) {
        return;
      }
      if (index >= this.slideData.length) {
        return;
      }
      this.isObserve = false;
      this.active = index;

      try {
        this.$refs.slide_wrap && this.$refs.slide_wrap.scrollTo({
        top: 122 * Number(index),
        behavior: "smooth",
        });
        this.$refs.content_wrap && this.$refs.content_wrap.scrollTo({
          top: 796 * Number(index),
          behavior: "smooth",
        });
      } catch (error) {
        this.$refs.slide_wrap && this.$refs.slide_wrap.scrollTo(0, 122 * Number(index));
        this.$refs.content_wrap && this.$refs.content_wrap.scrollTo(0, 796 * Number(index));
      }

      setTimeout(() => {
        this.isObserve = true;
      }, 1000);
    },
    handleClose(e) {
      this.$emit("onClose");
    },
    handleSetCookie() {
      SetCookie("micro-detail-tip", 1, 365);
      this.showTip = false;
    },
    handleToDetail(item) {
      const urlQuery = { ...this.$route.query };
      let url = urlQuery.origin_id && this.active==0 ? `?r=/l/d&id=${item.id || item.goodsId}&origin_id=${urlQuery.origin_id}&from=dtk` : `?r=/l/d&id=${item.id || item.goodsId}&from=dtk`;
      window.open(url, "_blank");
    },

    handleBiEvent(params, e) {
      let timestamp = new Date().getTime();
      const id = params.id;
      const goodsId = params.goodsId;
      const data = {
        site_id: this.config.taobao.uid,
        goodsid: `${goodsId}`,
        gid: id,
        pid: this.config.taobao.pid,
        need_tpwd: 1,
        auth_id:this.config.taobao.auth_id,//授权id
        not_use_commission_plan:1,//1不使用高佣计划
        need_short_link: 1,
        is_auto_quan: 1,
        need_item_link: 0,
        biz_scene_id:this.bizSceneId, // 场景id 1 动态id 2 消费比价场景 3商城导购场景
        // promotion_type: 2, // 1 自购挣  2 推广挣
        time: timestamp,
        d_title: "复制口令购买",
      };
      if (params.couponId) {
        data.quan_id = params.couponId;
      }
      if (e) {
        try {
          this.$BIJsdk.onEvent({
            event:e.target,
            extra:{
              // p: _data,
              ds: "cms-pc",
              referer: window.encodeURIComponent(location.href),
              ...data
            },
            event_type:e.type,
          })
        } catch (error) {
          
        }
      }
    },
    // 转链
    transformLink(params, index, callback, e) {
      if (!this.isTransformLink) {
        return;
      }
      this.isTransformLink = false;
      let timestamp = new Date().getTime();
      const id = params.id;
      const goodsId = params.goodsSign!=''? params.goodsSign : params.goodsId;
      const urlQuery = { ...this.$route.query };
      const data = {
        site_id: this.config.taobao.uid,
        goodsid: urlQuery.origin_id&&this.active==0 ? urlQuery.origin_id : `${goodsId}`,
        gid: id,
        pid: this.config.taobao.pid,
        need_tpwd: 1,
        auth_id:this.config.taobao.auth_id,//授权id
        not_use_commission_plan:1,//1不使用高佣计划
        need_short_link: 1,
        is_auto_quan: 1,
        need_item_link: 0,
        time: timestamp,
        biz_scene_id:1, // 场景id 1 动态id 2 消费比价场景 3商城导购场景
        // promotion_type: 2, // 1 自购挣  2 推广挣
        d_title: "复制口令购买",
      };
      if (params.couponId) {
        data.quan_id = params.couponId;
      }
      if (e) {
        this.handleBiEvent(params, e)
      }
      const _data = window.Base64.encodeURI(JSON.stringify(data));
      return this.$services
        .transformLink({
          p: _data,
          ds: "cms-pc",
          referer: window.encodeURIComponent(location.href),
          new_refer: (function(){
            var url = window.location.href;
            return url + (url.indexOf("?")>-1 ? "&" : "?") + 'event_id=cms&path='+encodeURIComponent('/goodslist/ljgm')+'&referer='+encodeURIComponent(document.referrer);
          })()
        })
        .then((res) => {
          this.isTransformLink = true;
          if (res && res.code === 1) {
            this.slideData[index].ShortLink = res.data.ShortLink;
            this.slideData[index].Link = res.data.Link;
            callback && callback(res.data);
            return res;
          }else{
            let url = this.transformFailed(goodsId, params.couponId, index);
            return url;
          }
        })
        .catch((err) => {
          console.log(err);
          let url = this.transformFailed(goodsId, params.couponId, index);
          return url;
        });
    },
    // 转链失败
    transformFailed(goodsId, couponId, index) {
      let url = `https://uland.taobao.com/item/edetail?id=${goodsId}`
      let currentDate = new Date();
      let targetDate = new Date("2023/09/28");
      if (currentDate < targetDate && this.config.taobao.pid) {
        url = `https://uland.taobao.com/coupon/edetail?activityId=${couponId || ''}&pid=${this.config.taobao.pid}&itemId=${goodsId}&src=qhkj_dtkp&dx=1`;
      }
      // 将链接转换成短链防止二维码内容过多
      return this.$services.createShort({url}).then(res=>{
        if(res.code == 1){
          this.slideData[index].ShortLink = res.data;
        }else{
          this.slideData[index].ShortLink = url;
        }
        return this.slideData[index].ShortLink
      })
    },
  },
  watch: {
    slideData() {
      const _this = this;
      this.$nextTick(() => {
        if (this.$refs.card_item) {
          let left = this.$refs.card_item[0]?this.$refs.card_item[0].getBoundingClientRect().left:0;
          let leftTwo = left + this.$refs.card_item[0].offsetWidth + 12 + "px";
          this.left = left - 80 + "px";
          this.slide_prod_left = leftTwo;
          let observer = new IntersectionObserver(
            (entries, observer) => {
              entries.forEach((entry, index) => {
                if (!_this.isObserve) {
                  return;
                }
                let item = entry.target;
                // 相交率，默认是相对于浏览器视窗
                if (entry.intersectionRatio > 0.9) {
                  _this.active = item.dataset.index;
                  try {
                    _this.$refs.slide_wrap.scrollTo({
                      top: 122 * Number(item.dataset.index),
                      behavior: "smooth",
                    });
                  } catch (error) {
                    _this.$refs.slide_wrap.scrollTo(0, 122 * Number(item.dataset.index));
                  }
                }
              });
            },
            { threshold: [0.9] }
          );
          this.$refs.card_item.forEach((el) => observer.observe(el));
        }
      });
      console.log(this.slideData);
    },
  },
};
</script>

<style scoped lang="less">
.active_mark ~ .active_tag {
  top: 485px !important;
}
.container {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10000;
  .tip {
    width: 560px;
    height: 52px;
    display: flex;
    align-items: center;
    background: rgba(0, 0, 0, 0.7);
    border: 1px solid rgba(255, 255, 255, 0.39);
    padding: 0 15px 0 12px;
    box-sizing: border-box;
    border-radius: 10px;
    margin: 0 auto;
    margin-top: 22px;
    i {
      width: 26px;
      height: 26px;
      background: url(https://cmsstaticnew.dataoke.com/images/tiny_imgs/icon1.png?v=2021105203122)
        no-repeat;
      background-size: 100%;
      flex-shrink: 0;
    }
    span.close_know {
      width: 74px;
      height: 26px;
      background: #fff;
      border-radius: 13px;
      text-align: center;
      line-height: 26px;
      flex-shrink: 0;
      cursor: pointer;
      font-size: 13px;
    }
    p {
      font-size: 13px;
      color: #fff;
      line-height: 18px;
      padding: 0 15px 0 10px;
    }
  }
  .content_wrap {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    overflow: auto;
    // width: 600px;
    // width: 100%;
    // height: 100%;
    // position: absolute;
    // left: 50%;
    // transform: translate(-50%, 80px);
    // top: 0;
  }
  .content {
    width: 600px;
    // overflow: auto;
    margin: 22px auto;
    border-radius: 10px;
    position: relative;
    .card_item_wrap {
      height: 774px;
      position: relative;
      margin-top: 22px;
    }
    .card_item {
      width: 600px;
      border-radius: 10px;
      background-color: #fff;
      padding-bottom: 30px;
      box-sizing: border-box;
      overflow: hidden;
      .page_num {
        position: absolute;
        top: 520px;
        right: 20px;
        z-index: 100;
        padding: 4px 10px;
        background-color: #000;
        color: #fff;
        border-radius: 15px;
      }
      .active_tag,
      .active_mark,
      .active_mark2 {
        width: 210px;
        height: 30px;
        background: url(https://cmsstaticnew.dataoke.com//images/tiny_imgs/active_bg2.png)
          center no-repeat;
        background-size: 100%;
        position: absolute;
        left: 20px;
        top: 520px;
        padding-right: 54px;
        box-sizing: border-box;
        line-height: 30px;
        font-size: 13px;
        color: #fff;
        text-align: right;
        z-index: 100;
      }
      .active_mark,
      .active_mark2 {
        background-image: linear-gradient(90deg, #ff7800 0%, #f81616 100%);
        border-radius: 50px;
        padding: 0;
        height: 30px;
        line-height: 30px;
        padding-right: 10px;
        text-align: center;
        left: 20px;
      }

      .active_mark2 {
        left: 0;
        width: 140px;
        padding: 0;
      }

      .dot_nav {
        position: absolute;
        top: 458px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        z-index: 100;
        i {
          width: 8px;
          height: 8px;
          background: rgba(51, 51, 51, 0.3);
          border-radius: 50%;
          margin: 0 3px;
          cursor: pointer;
        }
        i.active {
          width: 8px;
          height: 8px;
          background: #ff2e54;
        }
      }
      &:hover {
        .prev {
          display: block;
        }
        .next {
          display: block;
        }
      }
      .prev {
        border-radius: 0 10px 10px 0;
        background: url(../../assets/search/left.png) 6px 14px no-repeat
          rgba(0, 0, 0, 0.4);
        background-size: 7px 13px;
        left: 0;
        width: 20px;
        height: 40px;
        position: absolute;
        top: 280px;
        cursor: pointer;
        z-index: 100;
        display: none;
      }
      .next {
        border-radius: 10px 0 0 10px;
        background: url(../../assets/search/right.png) 8px 14px no-repeat
          rgba(0, 0, 0, 0.4);
        background-size: 7px 13px;
        right: 0;
        width: 20px;
        height: 40px;
        position: absolute;
        top: 280px;
        cursor: pointer;
        z-index: 100;
        display: none;
      }
      .img_wrap {
        height: 600px;
        display: flex;
        flex-wrap: nowrap;
        transition: 0.3s;
        img {
          width: 600px;
          height: 556px;
          // height: 520px;
          border-radius: 10px;
          flex-shrink: 0;
          object-fit: cover;
        }
      }
    }
    .card_info {
      width: 560px;
      height: 220px;
      background: #fff;
      border-radius: 10px;
      box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.12);
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      padding: 15px 15px 0;
      box-sizing: border-box;
      z-index: 200;
      .row1 {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        .left {
          display: flex;
          align-items: flex-end;
          .item1 {
            font-size: 22px;
            color: #ff0100;
            line-height: 26px;
          }
          .item2 {
            font-size: 29px;
            color: #ff0100;
            line-height: 30px;
            margin-right: 8px;
          }
          .item3 {
            font-size: 14px;
            color: #999;
            text-decoration: line-through;
            line-height: 22px;
          }
          .tag {
            height: 22px;
            line-height: 22px;
            font-size: 14px;
            color: red;
            background: #ffeaea;
            margin-left: 10px;
            padding: 0 10px;
            margin-bottom: 4px;
          }
        }
        .right {
          font-size: 12px;
          color: #999;
          line-height: 22px;
          .num {
            color: #fe2e54;
          }
        }
      }
      .row2 {
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;
        margin-top: 14px;
        .left {
          display: flex;
          align-items: center;
          overflow: hidden;
          img {
            width: 18px;
            height: 18px;
            flex-shrink: 0;
            margin-right: 4px;
          }
          .text {
            font-size: 18px;
            color: #333;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            word-break: break-all;
          }
        }
        .right {
          width: 200px;
          display: flex;
          flex-shrink: 0;
          cursor: pointer;
          & > span {
            width: 100px;
            height: 36px;
            font-size: 13px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .code {
            color: #f24000;
            background: #ffeed2;
            position: relative;
            border-radius: 18px 0 0 18px;
            &:hover .scan_qr {
              display: block;
            }
            .scan_qr {
              position: absolute;
              left: 50%;
              transform: translate(-50%, -98%);
              top: 0;
              display: none;
              padding-bottom: 15px;
              .qr_wrap {
                background: #fff;
                box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.18);
                border-radius: 6px;
                padding: 5px 5px 10px;
                position: relative;
                span {
                  width: 100px;
                  height: 100px;
                  display: block;
                  margin: 0 auto;
                }
                p {
                  font-size: 12px;
                  color: #f24000;
                  text-align: center;
                  line-height: 16px;
                  margin-top: 4px;
                }
                .sanjiao {
                  width: 0;
                  height: 0;
                  position: absolute;
                  border: 5px solid transparent;
                  border-top-color: #fff;
                  left: 50%;
                  bottom: 0;
                  transform: translate(-50%, 100%);
                }
                .loadingImg{
                  width: 100px;
                  height: 100px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  .loading {
                    background: #fff;
                    width: 30px;
                    height: 30px;
                    animation: loading 1s linear infinite;
                  }
                  @keyframes loading {
                    100% {
                      transform: rotate(360deg);
                    }
                  }
                }
              }
            }
            img {
              width: 11px;
              height: 11px;
              margin-right: 4px;
            }
            .code_text {
              color: #f24000;
              background: #ffeed2;
              position: relative;
              border-radius: 18px 0 0 18px;
            }
          }
          .buy {
            color: #fff;
            background: #ff2e54;
            border-radius: 0 18px 18px 0;
          }
        }
      }
      .row3 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 14px;
        & > span {
          display: flex;
          align-items: center;
        }
        .left {
          font-size: 14px;
          color: #333;
          font-weight: 700;
          img {
            width: 20px;
            height: 20px;
            margin-right: 6px;
          }
        }
        .right {
          font-size: 12px;
          color: #999;
          cursor: pointer;
          display: flex;
          align-items: center;
          img {
            width: 7px;
            height: 12px;
            margin-left: 5px;
          }
        }
      }
      .row4 {
        height: 64px;
        padding: 10px;
        background: #f2f2f2;
        border-radius: 5px;
        box-sizing: border-box;
        margin-top: 10px;
        position: relative;
        .sanjiao {
          width: 0;
          height: 0;
          border: 1px solid transparent;
          border-left-color: #f2f2f2;
          position: absolute;
          top: -8px;
          left: 40px;
          border-width: 8px 15px;
        }
        p {
          height: 100%;
          font-size: 13px;
          color: #666;
          line-height: 22px;
          overflow: hidden;
          text-overflow: -o-ellipsis-lastline;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }
  }
  .mask {
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
  }
  .loading {
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
  }
  .close {
    display: flex;
    align-items: center;
    font-size: 17px;
    color: #fff;
    cursor: pointer;
    opacity: 0.9;
    position: fixed;
    left: 30%;
    top: 50px;
    z-index: 100;
    i {
      width: 23px;
      height: 18px;
      background: url(../../assets/search/back.png) center no-repeat;
      background-size: 100%;
      margin-right: 8px;
    }
    span {
      font-size: 17px;
      color: #fff;
      cursor: pointer;
      opacity: 0.9;
    }
  }
  .slide_prod {
    width: 124px;
    position: fixed;
    top: 50px;
    left: 67%;
    z-index: 100;
    .prev {
      width: 27px;
      height: 16px;
      position: absolute;
      left: 55px;
      cursor: pointer;
      background: url(../../assets/search/up.png) center no-repeat;
      background-size: contain;
      top: -28px;
    }
    .next {
      width: 27px;
      height: 16px;
      position: absolute;
      left: 55px;
      cursor: pointer;
      background: url(../../assets/search/down.png) center no-repeat;
      background-size: contain;
      bottom: -28px;
    }
    .slide_wrap {
      width: 124px;
      max-height: 598px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      scrollbar-width: none;
      -ms-overflow-style: none;
      overflow: auto;
      &::-webkit-scrollbar {
        display: none;
      }
      .slide_wrap_move {
        transition: 0.3s;
        position: relative;
      }
      .slide_item {
        width: 110px;
        height: 110px;
        box-sizing: border-box;
        margin-bottom: 12px;
        position: relative;
        background: #fff;
        border-radius: 10px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 10px;
          display: block;
          cursor: pointer;
        }
        &.active::after {
          width: 8px;
          height: 13px;
          content: "";
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: -14px;
          background: url(../../assets/search/left_red.png) center no-repeat;
          background-size: contain;
        }
        &.active {
          border: 2px solid #ff2e54;
        }
      }
    }
  }
}
</style>
